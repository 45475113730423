.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.image-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-box {
  cursor: pointer;
  position: relative;
}

.image-box-thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

@keyframes drift {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(50vw, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(-50vw, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.image-box {
  margin: 10px;
  cursor: pointer;
  position: relative;
  animation: drift 10s infinite linear;
  animation-delay: calc(var(--delay) * 1s);
}

.image-box-thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.background-image {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.icons-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.App-footer {
  margin-top: 48vh;
  padding: 20px 0;
}

.icon {
  margin: 0 10px; /* Add some spacing between icons */
}

.icon-image {
  width: 150px; /* Adjust size as needed */
  height: 150px; /* Adjust size as needed */
  transition: transform 0.3s ease; /* Add transition for smooth scaling */
}

.icon-image:hover {
  transform: scale(1.2); /* Scale up the icon on hover */
}

.contract-address {
  margin-top: 20px; /* Add some spacing from the top */
  position: relative; /* Change to relative for normal flow */
  color: rgb(255, 255, 255);
  font-size: 1.5em;
  text-shadow: 0 0 5px rgb(0, 0, 0), 0 0 10px rgb(255, 255, 255), 0 0 15px rgb(0, 0, 0), 0 0 20px rgb(255, 255, 255), 0 0 25px rgb(0, 0, 0), 0 0 30px rgb(255, 255, 255), 0 0 35px rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}

.image3-container {
  width: 100%;
  z-index: 1; /* Ensure image3 is above the background */
}

.image3 {
  margin-top: 10vh;
  width: 100%;
  border-radius: 20px;
  height: auto;
}

.App-footer {
  font-size: 1em;
  color: rgb(0, 0, 0);
  text-shadow: 0 0 5px rgb(255, 255, 255), 0 0 10px rgba(0, 0, 255, 0), 0 0 15px rgb(255, 255, 255), 0 0 20px rgb(0, 0, 0), 0 0 25px rgb(255, 255, 255), 0 0 30px rgb(0, 0, 0), 0 0 35px rgb(255, 255, 255);
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  background-color: rgba(255, 255, 255, 0); /* Make the footer background transparent */
  padding: 10px;
  border-radius: 5px;
  position: relative; /* Ensure the copied message is positioned relative to the footer */
}

.copied-message {
  position: absolute;
  top: -30px; /* Position the message above the footer */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  text-shadow: none; /* Remove text shadow for better readability */
}

.landing-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1000;
}

.enter-button {
  padding: 15px 30px;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  border: 2px solid white;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative; /* Ensures the button stays on top of the image */
  z-index: 1001; /* Higher than the landing page z-index */
}

.enter-button:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}


@media (max-width: 600px) {
  .App {
    height: auto; /* Allow height to adjust based on content */
  }

  .background-container {
    height: 100%; /* Adjust height to cover the content */
  }

  .content {
    width: 100%;
  }

  .icons-bar {
    flex-wrap: wrap; /* Allow icons to wrap to the next line */
    margin-top: 4vh;
    margin-bottom: 2vh; /* Adjust spacing */
  }

  .icon {
    margin: 5px; /* Adjust spacing between icons */
  }

  .icon-image {
    width:76px; /* Adjust size for mobile */
    height: 76px; /* Adjust size for mobile */
  }

  .contract-address {
    font-size: 1em; /* Adjust font size for mobile */
    padding: 5px; /* Adjust padding */
  }

  .image3 {
    margin-left: 4vw;
    width: 100%; /* Ensure image takes full width */
    border-radius: 10px; /* Adjust border radius */
  }

  .App-footer {
    font-size: 0.8em; /* Adjust font size for mobile */
  }

  .copied-message {
    font-size: 0.7em;
  }

  .image-box-thumbnail {
    width: 80px; /* Adjust thumbnail size for mobile */
    height: 80px; /* Adjust thumbnail size for mobile */
  }


  
}